const { times } = require('lodash')
const { push } = require('browsernizr/lib/classes')

/** Bootstrap the application **/
require('./bootstrap')

require('./components/faq-view')
require('./components/timeline')
require('./components/slider')

// Create and mount the root Vue.js Artbox instance.
window.App = new Vue({
	el: '#app',
	delimiters: ['${', '}'],

	data() {
		return {
			loaded: false,
			showMenu: false,
			showVideo: false,

			subscribing: false,
			subscribed: false,

			handleImagesHasRun: false
		}
	},

	mounted() {
			this.initInstafeed()

			this.loaded = true
	},

	methods: {
		toggleMenu() {
			this.showMenu = !this.showMenu
			document.body.classList.toggle('menu-active')
			var hamburger = document.querySelector('#hamburger').classList
			hamburger.toggle('active')
			var menuHolder = document.querySelector('#menu-holder').classList
			menuHolder.toggle('menu-active')
		},

		toggleVideo() {
			this.showVideo = !this.showVideo
			document.body.classList.toggle('overlay-active')
		},

		/**
		 * Subscribe the given email to Campaign Monitor
		 *
		 * @return {void}
		 */
		subscribe() {
			var serialize = require('form-serialize')
			let data = serialize(document.getElementById('form-newsletter'), { hash: true })

			this.subscribing = true

			Vue.http.post('/', data, { emulateJSON: true })
			.then((response) => {
				if (response.data) {
					this.subscribed = true
				}
				this.subscribing = false
			}, (response) => {
				this.subscribing = false
			})
		},

		initInstafeed() {
			if (!document.getElementById('latest-instagram-post')) {
				return false
			}

			var allImages = [];
			var token = 'IGQVJVUmpwMGpfOEZA3Y01NbWU5ZA2JtQnR3TTFaSmhiLWQ0NVhkUEJVR2NIR3d1eVJBVmhvYzVVX3RZAc0x5N2gzQ2FYNmtVNEMycXBuZA2plUVlaOVE2LTFyTEJWVmRScVlTb0xaZAWY4QmVfOWMxUlZAoXwZDZD';

			if (!token) {
				return;
			}

			// Get short lived (24h valid) access token via: https://developers.facebook.com/docs/instagram-basic-display-api/getting-started
			// Make it a long lived access token via: https://developers.facebook.com/docs/instagram-basic-display-api/guides/long-lived-access-tokens/
			// Past code down below, code is valid for 60 days.
			var feed = new Instafeed({
				accessToken: token,
				target: 'latest-instagram-post',
				limit: 1,
				mock: true,
				filter: (image) => {
					allImages.push(image);
					this.handleImages(allImages[0]);
				}
			});
			feed.run();
		},

		handleImages(image) {
			// Prevent run without data resulting in errors
			// prevent running more than once, so that only the most recent photo is shown. Limit option via instafeed api won't work now (03-09-2020).
			if (!image || this.handleImagesHasRun) {
				return;
			}

			this.handleImagesHasRun = true;

			var username = image.model.username;
			var originalTimestamp = image.model.timestamp;
			var imageSrc = image.image;
			var imageLink = image.link;
			var caption = image.caption + image.caption + image.caption + image.caption;
			var timestamp;

			if (caption) {
					var index = 120
					while (caption.substr(index, 1) !== ' ') {
						index++;
						if (index == 500) break;
					}
					caption = caption.substr(0, index);

			} else {
				caption = '';
			}

			if (caption) {
				var oneDay = oneDay = 24 * 60 * 60 * 1000;
				var today = new Date();
				var trimmedTimestamp = new Date(originalTimestamp.split('T')[0]);

				var diffDays = Math.round(Math.abs((today - trimmedTimestamp) / oneDay));

				if (diffDays === 0) {
					timestamp = 'Today';
				} else if (diffDays === 1) {
					timestamp = diffDays + ' day ago';
				} else {
					timestamp = diffDays + ' days ago';
				}
			}

			// append to DOM
			var instaEl = document.querySelector('#latest-instagram-post');
			var imageLinkEl = instaEl.querySelector('a');
			var imageEl = instaEl.querySelector('.image');
			var postInfoEl = instaEl.querySelector('.post-information');
			var captionEl = instaEl.querySelector('.post-caption');

			imageLinkEl.href = imageLink;
			imageEl.src = imageSrc;
			imageEl.alt = caption;
			postInfoEl.querySelector('span').innerText = '@' + username + ' - ' + timestamp;
			captionEl.innerText = caption;
		}

	}
})
