import Vue from 'vue';
import Glide from '@glidejs/glide'

Vue.component('slider', {
    props: {

    },

    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            currentSlide: 1,
        }
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        this.initSlider();
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        initSlider() {
            const glides = document.querySelectorAll('.glide__slider');
            if ([...glides].length == 0) {
                return;
            }
            for (var i = 0; i < glides.length; i++) {
                this.glide = new Glide(glides[i], {
                    type: 'carousel',
                    startAt: 0,
                    gap: 0,
                    perView: 1,
                    peek: 0,
                    autoplay: 10000
                });

                this.glide.on('swipe.start', () => {
                    TweenMax.delayedCall(0.5, () => {

                        this.dragging = true;
                    });
                });

                this.glide.on('swipe.end', () => {
                    TweenMax.delayedCall(0.5, () => {
                        this.dragging = false;
                    });
                });

                this.glide.on('move.after', (event) => {
                    //console.log(this.glide.index);
                    this.currentSlide = this.glide.index+1;
                });
                

                this.glide.mount();
            }
        },
    },
});
