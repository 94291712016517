Vue.component('faq-view', {
	  delimiters: ['${', '}'],

		data() {
        return {
        }
    },

    mounted() {
    },

    methods: {
			toggleFaqQuestion(event) {
				let questionElement = event.currentTarget.parentNode
				questionElement.classList.toggle('expanded')
			}
    }

})
